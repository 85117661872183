<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="兑换券名称">
                        <el-input v-model="form.coupon_scoree_title"></el-input>
                    </el-form-item>
                    <el-form-item label="兑换券封面">
                        <span class="form-tip">不设置将使用默认封面</span>
                        <Qnupload v-model="form.cover" :sum="1"/>
                    </el-form-item>
                    <el-form-item label="所需积分">
                        <el-input-number v-model="form.score" :step="1" :min="1"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="总数量（库存）">
                        <el-input-number v-model="form.quota" :step="1" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="优惠券">
                        <el-select
                                v-model="form.coupon_uuid"
                                filterable
                                placeholder="请选择优惠券"
                                clearable
                                remote
                                :remote-method="get_coupon_list"
                                @clear="get_coupon_list"
                                :loading="select_loading"
                                style="width: 100%">
                            <el-option
                                    v-for="item in coupon_list"
                                    :key="item.coupon_uuid"
                                    :label="item.coupon_title"
                                    :value="item.coupon_uuid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="兑换券规则">
                        <el-input v-model="form.coupon_scoree_rule" type="textarea"
                                  :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button v-if="is_auth('coupon.scoreexchange.isadd')"
                                   size="medium" type="primary" @click="save">保存
                        </el-button>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: false,
            select_loading: false,
            coupon_list: [],                    // 优惠券列表

            form: {
                coupon_scoree_title: '',        // 兑换券名称
                cover: '',                      // 兑换券封面
                score: 0,                       // 所需积分
                quota: 0,                       // 总数量
                coupon_uuid: '',                // 可用优惠券
                coupon_scoree_rule: '',         // 兑换券规则
            },
        }
    },
    // 创建
    created() {
        this.get_coupon_list(null)          // 获取可用优惠券列表
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取优惠券列表
        get_coupon_list(key) {
            // if (key === '') return false;
            let postdata = {
                api_name: 'coupon.scoreexchange.getusablecoupon',
                token: this.Tool.get_l_cache('token'),
                key,
            }
            this.select_loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.select_loading = false
                if (json.code === 0) {
                    this.coupon_list = json.data
                }
            })
        },
        //保存
        save() {
            let postdata = {
                api_name: "coupon.scoreexchange.isadd",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.isreturn();        // 返回到列表
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appoint {
    border-left: 10px solid #409EFF;
}
</style>
